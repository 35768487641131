import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import SEO from '../components/seo';
import Layout from '../components/layout';
import { H2White, H4White } from '../components/typography';
import { getQueryString } from '../components/getQueryString';
import Arrow from '../images/arrow-right.svg';
import Chevron from '../images/chevron.svg';
import ProjectIndex from '../components/projectIndex';

const StyledGrid = styled(Grid)`
  max-width: 89rem;
  margin-bottom: 50px;
  width: 100%;

  @media (min-width: 64em) {
    margin-bottom: 106px;
  }
`;

const StyledH2White = styled(H2White)`
  line-height: 2.1rem;
  margin-bottom: 35px;

  @media (min-width: 64em) {
    margin-top: 69px;
  }
`;

const StyledH2WhiteRight = styled(StyledH2White)`
  cursor: pointer;
  text-align: left;

  @media (min-width: 64em) {
    text-align: right;
  }

  &:hover,
  &:focus {
    color: var(--blue);
    transition: color .5s ease;
  }
`;

const Container = styled.div`
  position: relative;
  margin-bottom: 2.25rem;

  &:hover .overlay {
    opacity: 0.9;
  }

  &:hover .title {
    color: var(--blue);
    transition: color .5s ease;
  }
`;

const OverlayWrapper = styled.div`
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008CBA;
`;

const Button = styled.button`
  color: var(--white);
  width: 182px;
  height: 43px;
  border: 1px solid var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  letter-spacing: 0.32px;
  line-height: 1.125rem;
`;

const StyledLink = styled(Link)`
  &:hover,
  &:focus {
    & > svg {
      fill: var(--blue);
      transition: fill .5s ease;
    }
  }
`;

const ArrowRight = styled(Arrow)`
  height: 20px;
  fill: var(--white);
  margin-left: 8px;

  @media (max-width: 64em) {
    display: none;
  }
`;

const StyledSelect = styled(Select)`
  margin-bottom: 35px;

  @media (min-width: 64em) {
    margin-top: 69px;
  }
`;

const DropdownIndicator = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.DropdownIndicator {...props}>
    <Chevron />
  </components.DropdownIndicator>
);

const selectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'var(--black)',
    borderColor: 'transparent',
    cursor: 'pointer',
    outline: 'none',
    boxShadow: 'none',
    color: 'var(--white)',
    borderRadius: 'unset',
    borderStyle: 'unset',
    borderWidth: 'unset',
    minHeight: 'unset',

    '&:hover': {
      borderColor: 'transparent',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'var(--black)',
    borderRadius: 'unset',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    backgroundColor: 'var(--black)',
    height: '2.1rem',
  }),
  input: (provided) => ({
    ...provided,
    backgroundColor: 'var(--black)',
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: 'var(--black)',
    color: 'var(--white)',
    cursor: 'pointer',
    fontSize: '1.125rem',
    fontWeight: 300,
    letterSpacing: '1px',
    lineHeight: '1.333rem',

    '&:hover': {
      color: 'var(--blue)',
      textDecoration: 'none',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--white)',
    fontSize: '1.75rem',
    fontWeight: 300,
    letterSpacing: '1.56px',
    lineHeight: '2.1rem',
  }),
  indicatorSeparator: () => ({
    display: 'none',
    visibility: 'hidden',
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: 0,
    color: 'var(--white)',

    '&:hover': {
      color: 'var(--blue)',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
    color: 'var(--white)',
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0)',
    width: '25px',

    svg: {
      height: '25px',
      width: '25px',
    },

    '&:hover': {
      color: 'var(--blue)',
    },
  }),
};

const Projects = ({ data }) => {
  const [filterValue, setfilterValue] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const filterString = decodeURIComponent(getQueryString('filter'));
  const projectsList = data.allDatoCmsProject.edges;
  const [showIndex, setShowIndex] = useState(false);
  // eslint-disable-next-line no-shadow
  const filter = (filterString) => projectsList.filter(({
    node: project,
  }) => project.category.find((p) => p.name === filterString));

  const categories = [];
  data.allDatoCmsProject.edges.map(({ node: project }) => {
    if (project.category.length >= 1) {
      project.category.map((value) => categories.push(value.name));
    }

    return null;
  });
  const options = Array.from(new Set(categories)).map((value) => ({ value, label: value }));
  options.unshift({ value: 'featured projects', label: 'featured projects' });

  useEffect(() => {
    setFilteredProjects(projectsList);
    setfilterValue(options[0]);
    if (filterString !== 'null') {
      const filterProjects = filter(filterString);
      setFilteredProjects(filterProjects);
      setfilterValue({ value: `${filterString}`, label: `${filterString}` });
    }
  }, []);

  const handleFilter = (e) => {
    setfilterValue(e);
    const val = encodeURIComponent(e.value);
    let url = `/projects?filter=${val}`;
    let filterProjects = filter(e.value);

    if (e.value === 'featured projects') {
      url = '/projects';
      filterProjects = projectsList;
    }

    navigate(url);
    setFilteredProjects(filterProjects);
  };

  const allText = () => {
    let text = null;
    if (filterString !== 'null') {
      text = (
        <StyledH2WhiteRight>
          <StyledLink to={`/allprojects?category=${encodeURIComponent(filterString)}`}>
            all
            {' '}
            {filterString}
            <ArrowRight />
          </StyledLink>
        </StyledH2WhiteRight>
      );
    }

    return text;
  };

  const preventScroll = () => {
    document.body.classList.add('prevent-scroll');
  };

  return (
    <Layout>
      <ProjectIndex showIndex={showIndex} setShowIndex={setShowIndex} />
      <SEO title="Projects" />
      <StyledGrid fluid>
        <Row>
          <Col lg={4} md={4} xs={12}>
            <StyledSelect
              options={options}
              components={{ DropdownIndicator }}
              isSearchable={false}
              styles={selectStyles}
              value={filterValue}
              onChange={(e) => { handleFilter(e); }}
            />
          </Col>
          <Col lg={4} md={4} xs={12}>
            {allText()}
          </Col>
          <Col lg={4} md={4} xs={12}>
            <StyledH2WhiteRight
              onClick={(e) => {
                e.preventDefault();
                preventScroll();
                setShowIndex(!showIndex);
              }}
            >
              all projects
            </StyledH2WhiteRight>
          </Col>
        </Row>
        <Row>
          {filteredProjects.map(({ node: project }) => (
            <Col lg={4} md={4} xs={12} key={project.id}>
              <Link to={`/projects/${project.slug}`}>
                <Container>
                  <OverlayWrapper>
                    <Img fluid={{ ...project.cover.fluid, aspectRatio: 1 }} className="project-image" />
                    <Overlay className="overlay">
                      <Button type="button">Read more</Button>
                    </Overlay>
                  </OverlayWrapper>
                  <H4White className="title">{project.title}</H4White>
                </Container>
              </Link>
            </Col>
          ))}
        </Row>
      </StyledGrid>
    </Layout>
  );
};

export const query = graphql`
  query ProjectsIndexQuery {
    allDatoCmsProject(
      sort: {
        fields: [meta___publishedAt],
        order: DESC
      },
      filter: {
        meta: { 
          isValid: { eq: true },
          status: { eq: "published" },
        }
        featured: { eq: true },
      }
    ) {
      edges {
        node {
          id
          title
          slug
          category {
            name
          }
          featured
          meta {
            publishedAt
            isValid
            status
          }
          cover {
            fluid(maxHeight: 375) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;

Projects.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Projects;
